import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { LANGUAGE } from '../host';
import { LanguageService } from './core/services/language.service';
import { PlatformService } from './core/services/platform.service';
import { MessageService } from './shared/services/message.service';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly META_KEYWORD_KO =
    '고객관리,멤버십 앱,키오스크,모바일 주문,비대면,언택트,untact,스마트오더,비대면결제';

  readonly META_KEYWORD_JA =
    '顧客管理,メンバーシップアプリ,キオスク,モバイルオーダー,非対面接触 / アンタクト(untact),スマートオーダー,非対面決済';

  title = 'hp-front-paywith';

  currentUrl: string;

  fullPath: string;

  isComplete = false;

  outputParam: string;

  isDisabled = false;

  isCrnty = false;

  constructor(
    @Inject('SERVER_URL') public serverUrl: string,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _message: MessageService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    @Optional()
    @Inject(LANGUAGE)
    private languageCd: string,
    private languageService: LanguageService,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {
    let lang = '';
    if (this.platformService.isPlatformServer) {
      lang = this.languageCd;
      this.document.documentElement.lang = lang;
    } else {
      // 서버에서 설정된 언어
      lang = this.document.documentElement.lang;
    }

    this.languageService.changeLang(lang);
    this.updateDocumentByLang(lang);

    if (lang !== 'ko') {
      this.isCrnty = true;
    }

    this.router.events.subscribe((val) => {
      this.fullPath = this.router.url;
      this.currentUrl = this.fullPath.replace('/', '').substr(0, 3);
    });

    this.initLanguage();
  }

  onActivate(event) {
    if (this.platformService.isPlatformBrowser) {
      this.document.body.parentElement.scroll({ top: 0 });
    }
  }

  /** 솔루션페이지로 이동 */
  onClickSolution(): void {
    this.router.navigate(['solution']);
  }

  /** 회사소개 페이지 이동 */
  onClickCompanyIntro(): void {
    this.router.navigate(['company-intro']);
  }

  /** 문의하기 페이지 이동 */
  onClickQna(): void {
    this.router.navigate(['qna']);
  }

  getParam(data: string) {
    const param = data;
  }

  openNav(): void {
    document.getElementById('mySidenav').style.height = '245px';
    (document.querySelector('.bg-overlay') as HTMLElement).style.display =
      'block';
  }

  closeNav(): void {
    document.getElementById('mySidenav').style.height = '0';
    (document.querySelector('.bg-overlay') as HTMLElement).style.display =
      'none';
  }

  private getMetaByAttr(
    attrName: string,
    attrValue: string
  ): {
    _attrsByQName: {
      [key: string]: { data: string };
      content: { data: string };
    };
  } {
    return <any>Array.from(
      this.document.head.getElementsByTagName('meta')
    ).find((meta: any) => {
      return meta._attrsByQName[attrName]?.data === attrValue;
    });
  }

  private initLanguage(): void {
    this.activatedRoute.queryParams
      .pipe(
        filter(({ lang }) => this.languageService.lang !== lang),
        tap(({ lang }) => {
          if (lang) {
            this.languageService.changeLang(lang);
            this.updateDocumentByLang(lang);
          }
        })
      )
      .subscribe();
  }

  private updateDocumentByLang(lang: string): void {
    // HTML 변경은 가능한 서버에서만 하도록
    if (this.platformService.isPlatformServer) {
      const keywordMeta = this.getMetaByAttr('name', 'keyword');
      const descriptionMeta = this.getMetaByAttr('name', 'description');
      const ogTitleMeta = this.getMetaByAttr('property', 'og:title');
      const ogDescriptionMeta = this.getMetaByAttr(
        'property',
        'og:description'
      );
      const ogImageMeta = this.getMetaByAttr('property', 'og:image');
      const ogUrlMeta = this.getMetaByAttr('property', 'og:url');

      if (lang === 'ko') {
        this.document.head.getElementsByTagName('title')[0].textContent =
          'PAYWITH';
        keywordMeta._attrsByQName.content.data = this.META_KEYWORD_KO;
        descriptionMeta._attrsByQName.content.data = 'PAYWITH';
        ogTitleMeta._attrsByQName.content.data = '페이위드';
        ogDescriptionMeta._attrsByQName.content.data = 'PAYWITH';
        ogImageMeta._attrsByQName.content.data =
          'https://paywith.co.kr/assets/images/logo_bk.png';
        ogUrlMeta._attrsByQName.content.data = 'https://paywith.co.kr';
      } else {
        this.document.head.getElementsByTagName('title')[0].textContent =
          'CARENITY';
        keywordMeta._attrsByQName.content.data = this.META_KEYWORD_JA;
        descriptionMeta._attrsByQName.content.data = 'CARENITY';
        ogTitleMeta._attrsByQName.content.data = 'CARENITY';
        ogDescriptionMeta._attrsByQName.content.data = 'CARENITY';
        ogImageMeta._attrsByQName.content.data =
          'https://carenity.co.jp/assets/images/crnty/crnty-logo.png';
        ogUrlMeta._attrsByQName.content.data = 'https://carenity.co.jp';
      }
    }
  }
}
