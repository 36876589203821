import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  logoPath = '/assets/images/renewal-2nd/footer-logo-img.png';

  constructor(private languageService: LanguageService) {}

  ngOnInit() {
    if (this.languageService.lang === 'ja') {
      this.logoPath = '/assets/images/crnty/crnty-logo.png';
    }
  }
}
