import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'weekday',
})
export class WeekdayPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  /**
   * @param value: string
   */
  transform(value: string): string {
    if (value === undefined || value === null) {
      // 없으면 반환
      return '';
    }

    try {
      const data = JSON.parse(value);
      if (Array.isArray(data)) {
        return data
          .map((week: string) => {
            return this.translateService.instant(
              `WEEKDAY.${week.substring(0, 3).toLowerCase()}`
            );
          })
          .join(', ');
      }
    } catch (e) {
      //
    }

    return value;
  }
}
