import { Component, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent extends FieldType {
  @ViewChild(MatInput, { static: true }) formFieldControl!: MatInput;

  get type() {
    return this.to.type || 'text';
  }
}
