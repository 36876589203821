import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-floating',
  templateUrl: './floating.component.html',
  styleUrls: ['./floating.component.scss'],
})
export class FloatingComponent implements OnInit {
  @Output() param = new EventEmitter();

  constructor(
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  ngOnInit(): void {}

  goToTop(): void {
    this.document.body.parentElement.scroll({ top: 0, behavior: 'smooth' });
  }

  /**test */
  passParam(data: string): void {
    this.param.emit(data);
  }
}
