import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRouterModule } from './app-router.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/modules/material.module';
import { DialogsModule } from './shared/components/dialogs/dialogs.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DEFAULT_LANGUAGE, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PipesModule } from './shared/pipes/pipes.module';
import { FooterComponent } from './layout/footer/footer.component';
import { FloatingModule } from './layout/floating/floating.module';
import { isPlatformBrowser } from '@angular/common';
import { HOST_ORIGIN, LANGUAGE } from '../host';

const createTranslateLoader = (
  http: HttpClient,
  platformId: object,
  origin: string
) => {
  if (isPlatformBrowser(platformId)) {
    origin = location.origin;
  }
  return new TranslateHttpLoader(http, `${origin}/assets/i18n/`, '.json');
};

@NgModule({
  declarations: [AppComponent, FooterComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRouterModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, PLATFORM_ID, [new Optional(), HOST_ORIGIN]],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    DialogsModule,
    SharedModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    FloatingModule,
  ],
  providers: [
    { provide: 'SERVER_URL', useValue: environment.serverUrl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
