import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/shared/modules/material.module';
import { SharedModule } from '../../shared.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';

@NgModule({
  declarations: [
    MessageDialogComponent,
    ConfirmDialogComponent,
    InputDialogComponent,
  ],
  imports: [CommonModule, MaterialModule, SharedModule],
  entryComponents: [
    MessageDialogComponent,
    ConfirmDialogComponent,
    InputDialogComponent,
  ],
})
export class DialogsModule {}
