<h2 mat-dialog-title *ngIf="header">{{ header }}</h2>
<mat-dialog-content>
  {{ body }}
  <!-- <div [innerHTML]="body"></div>
  <div [innerHTML]="'Continue?' | translate"></div> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="basic" (click)="onclick(false)">
    {{ 'cancel' | translate }}
  </button>
  <button mat-button color="warn" (click)="onclick(true)">
    {{ 'ok' | translate }}
  </button>
</mat-dialog-actions>
