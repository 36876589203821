import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ThemePalette } from '@angular/material/core';
import { MatBadgePosition } from '@angular/material/badge';
import { MessageService } from 'src/app/shared/services/message.service';

export interface ButtonWrapper {
  label: string;
  color?: ThemePalette;
  disabled?: () => boolean;
  canShow?: () => boolean;
  clickButton: () => any;
  matBadge?: () => string;
  matBadgePosition?: () => MatBadgePosition;
  matBadgeColor?: () => ThemePalette;
}

@Component({
  selector: 'app-buttons-wrapper',
  templateUrl: './buttons-wrapper.component.html',
  styleUrls: ['./buttons-wrapper.component.scss'],
})
export class ButtonsWrapperComponent extends FieldWrapper implements OnInit {
  public privateText = `
  페이위드는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에 따라 다음과 같이 개인정보를 수집ㆍ이용합니다.<br>
  본 개인정보의 수집ㆍ이용에 동의하지 않으실 경우 상담 서비스를 받을 수 없습니다.<br>
  <br>
  1. 개인정보 수집ㆍ이용 목적<br>
  (1) 서비스 이용 상담<br>
  <br>
  2. 수집하는 개인정보<br>
  (1) 문의/상담 : 휴대전화번호, 이메일<br>
  <br>
  3. 개인정보의 보유ㆍ이용 기간<br>
  (1) 수집된 개인정보는 원칙적으로 상담 완료시까지 이용됩니다.<br>
  (2) 상담 완료 이후에도 개인정보를 이용하여야 하는 사유가 있는 경우를 제외하고는 개인정보를 지체 없이 관련 법령이 정한 바에 따라 파기합니다.
  `;

  constructor(private _message: MessageService) {
    super();
  }

  ngOnInit(): void {}

  public get buttons(): ButtonWrapper[] {
    return this.to.buttons;
  }

  public onClickButton(index: number): void {
    // this.to.buttons[index].clickButton();
    this._message.alert(this.privateText, '개인정보 수집 동의').subscribe();
  }
}
