import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  header: string;
  body: string;
  btnName = 'OK';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>, //
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.header) {
        this.header = data.header;
      }
      if (data.btnName) {
        this.btnName = data.btnName;
      } else {
        this.btnName = data.header;
      }
      if (data.body) {
        this.body = data.body;
      }
    }
  }

  ngOnInit() {}

  onclick(b) {
    this.dialogRef.close(b);
  }
}
