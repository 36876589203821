import { ComponentType } from '@angular/cdk/portal';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog/confirm-dialog.component';
import {
  InputDialogComponent,
  InputDialogConfig,
} from '../components/dialogs/input-dialog/input-dialog.component';
import { MessageDialogComponent } from '../components/dialogs/message-dialog/message-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private MAX_CNT = 1;
  private _currentCnt = 0;

  constructor(
    private _dialog: MatDialog, //
    private _snackBar: MatSnackBar
  ) {}

  public alert(
    body: string,
    header: string = null,
    data = null
  ): Observable<any> {
    if (this._currentCnt < this.MAX_CNT) {
      this._currentCnt++;
      const dialogRef = this._dialog.open(MessageDialogComponent, {
        data: {
          header,
          body,
        },
        hasBackdrop: true,
      });
      return dialogRef
        .afterClosed() //
        .pipe(
          map(() => {
            this._currentCnt--;
            return data;
          })
        )
        .pipe(take(1));
    } else {
      return of(null).pipe(take(1));
    }
  }

  public confirm(body: string, header: string = null): Observable<any> {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      data: {
        header,
        body,
      },
      disableClose: true,
    });
    return dialogRef //
      .afterClosed()
      .pipe(take(1));
  }

  public input(config: InputDialogConfig): Observable<any> {
    const dialogRef = this._dialog.open(InputDialogComponent, {
      data: { ...config },
      disableClose: true,
    });
    return dialogRef.afterClosed().pipe(take(1));
  }

  public toast(
    message: string,
    btnLabel: string = null,
    options = { duration: 2000 }
  ) {
    this._snackBar.open(message, btnLabel, options);
  }

  public dialog<T, D = any, R = any>(
    component: ComponentType<any>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    const defaultConfig = new MatDialogConfig();
    defaultConfig.width = '90vw';
    defaultConfig.disableClose = false;
    // defaultConfig.disableClose = true;
    return this._dialog.open(component, { ...defaultConfig, ...config });
  }

  public httpMessage(err?: HttpErrorResponse): Observable<any> {
    let message: string;
    let header: string;
    let b: boolean;

    if (err) {
      b = false;
      header = '오류';
      if (err && err.error && err.error.content) {
        message = err.error.content[0].defaultMessage;
      } else if (
        err &&
        err.error &&
        err.error.message !== 'No message available'
      ) {
        message = err.error.message;
      } else {
        message = '오류가 발생했습니다. 관리자에게 문의해주세요.';
      }
    } else {
      message = '저장되었습니다.';
      header = '완료';
      b = true;
    }

    return this.alert(message, header, b);
  }
}
