import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeResourceUrl',
})
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private dom: DomSanitizer) {}

  transform(value: string, ...args: never[]): SafeResourceUrl {
    return this.dom.bypassSecurityTrustResourceUrl(value);
  }
}
