<!-- <mat-toolbar color="primary" class="grid-toolbar">
  <mat-toolbar-row>
    <div class="inner">
      <button mat-icon-button class="toggle-button item" (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
      <h1 id="logo" class="item">
        <img src="/assets/images/logo_white_1.5-1.png" alt="PAYWITH" class="logo-wt">
        <img src="/assets/images/logo_color_1.5-1.png" alt="PAYWITH" class="logo-bk">
      </h1>
      <div class="item">
        <a _ngcontent-vip-c1="" class="btn-header-move-consult" (click)="onClickContactDialog()">문의하기</a>
      </div>
      <nav id="lnb">
        <ul>
          <li><a (click)="onClickMoveDevelop()">개발</a></li>
          <li><a (click)="onClickMoveMembership()">스마트 오더</a></li>
          <li><a (click)="onClickContactDialog()">문의하기</a></li>
        </ul>
      </nav>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container (backdropClick)="sidenav.close()">
  <mat-sidenav
    #sidenav
    fixedBottomGap="0"
  >
    <mat-nav-list (clickMenu)="sidenav.close()">
      <mat-list-item  class="parent">
				<mat-icon mat-list-icon>home</mat-icon>
				<mat-icon class="menu-button" >expand_more</mat-icon>
			</mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="main-content-wrapper">
    <main class="main-content">
      <router-outlet (activate)="onActivate($event)" ></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
<router-outlet (activate)="onActivate($event)" ></router-outlet>
<app-footer></app-footer> -->

<mat-toolbar color="primary" class="grid-toolbar">
  <mat-toolbar-row>
    <div class="inner">
      <button mat-icon-button class="toggle-button" (click)="openNav()">
        <mat-icon>menu</mat-icon>
      </button>
      <label id="logo" class="item" for="home" routerLink="/">
        <ng-container *ngIf="isCrnty; else paywith">
          <img src="../assets/images/crnty/crnty-logo.png" alt="CARENITY" />
        </ng-container>
        <ng-template #paywith>
          <img
            src="../assets/images/renewal-2nd/logo-re2.png"
            alt="PAYWITH"
            class="logo-wt"
          />
          <img
            src="../assets/images/renewal-2nd/logo-renewal-bk.png"
            alt="PAYWITH"
            class="logo-bk"
          />
        </ng-template>
      </label>
      <!-- <div class="item">
        <a _ngcontent-vip-c1="" class="btn-header-move-consult" (click)="onClickContactDialog()">문의하기</a>
      </div> -->
      <nav id="lnb">
        <ul>
          <input type="radio" checked name="header" id="home" />
          <label class="header" for="home" routerLink="">{{
            'home' | translate
          }}</label>
          <input
            type="radio"
            [checked]="currentUrl === 'com' ? true : false"
            name="header"
            id="company"
          />
          <label class="header" for="company" routerLink="company-intro">{{
            'companyIntro' | translate
          }}</label>
          <input
            type="radio"
            [checked]="currentUrl === 'sol' ? true : false"
            name="header"
            id="solution"
            routerLink="solution"
          />
          <label class="header" for="solution">{{
            'solution' | translate
          }}</label>
          <input
            type="radio"
            [checked]="
              currentUrl === 'qna' || outputParam === 'form' ? true : false
            "
            name="header"
            id="qna"
          />
          <label
            class="header"
            (passParam)="($event)"
            for="qna"
            routerLink="qna"
            >{{ 'contact' | translate }}</label
          >
        </ul>
      </nav>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="bg-overlay" (click)="closeNav()"></div>
<div id="mySidenav" class="sidenav">
  <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
  <a [routerLink]="['/company-intro']" (click)="closeNav()">
    {{ 'companyIntro' | translate }}
  </a>
  <a [routerLink]="['/solution']" (click)="closeNav()">
    {{ 'solution' | translate }}
  </a>
  <a [routerLink]="['/qna']" (click)="closeNav()">
    {{ 'contact' | translate }}
  </a>
</div>

<mat-sidenav-container>
  <!-- <mat-sidenav #sidenav fixedBottomGap="0" [fixedTopGap]="56" [fixedInViewport]="true">
    <mat-nav-list>
      <ul>
        <li>
          <mat-icon>developer_mode</mat-icon>
          <a (click)="onClickMoveDevelop(); sidenav.close()">개발</a>
        </li>
        <li>
          <mat-icon>local_grocery_store</mat-icon>
          <a (click)="onClickMoveMembership(); sidenav.close()">스마트 오더</a>
        </li>
      </ul>
    </mat-nav-list>
  </mat-sidenav> -->

  <mat-sidenav-content class="main-content-wrapper">
    <main class="main-content">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
      <!--       <app-floating (handleclick)="getOption($event)"></app-floating> -->
      <app-floating
        ngClass="hasValue"
        (param)="getParam($event)"
      ></app-floating>
      <app-footer></app-footer>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
