import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormControl,
  ValidationErrors,
} from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { TranslateModule } from '@ngx-translate/core';

import { InputComponent } from './components/formly/template/input/input.component';
import { ButtonsWrapperComponent } from './components/formly/wrapper/buttons-wrapper/buttons-wrapper.component';
import { MaterialModule } from './modules/material.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'required', message: '필수 항목입니다.' },
        { name: 'pwMatch', message: '비밀번호가 일치하지 않습니다.' },
        { name: 'duplicated', message: '중복되는 정보가 존재합니다.' },
        { name: 'email', message: '이메일 형식에 맞지 않습니다.' },
      ],
      wrappers: [{ name: 'buttons', component: ButtonsWrapperComponent }],
      types: [
        { name: 'input', component: InputComponent },
        {
          name: 'buttons-input',
          component: InputComponent,
          wrappers: ['buttons', 'form-field'],
        },
      ],
    }),
  ],
  exports: [ReactiveFormsModule, TranslateModule, FormsModule, FormlyModule],
  declarations: [ButtonsWrapperComponent, InputComponent],
})
export class SharedModule {}
