import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from './platform.service';
// import { PW_SUPPORTED_LOCALES } from 'projects/pw-lib/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  static readonly FALLBACK_LANG = 'ja';

  /**
   * 현재 선택한 언어
   */
  #lang: string;

  /**
   * 현재 선택한 언어가 없다면 스토리지 > 브라우저 기본 값 중 지원원어, en 순으로 확인하며 가져온다
   */
  get lang(): string {
    if (this.#lang) {
      return this.#lang;
    }

    if (this.platformService.isPlatformBrowser) {
      this.#lang =
        localStorage.getItem('lang') ??
        (navigator.language || LanguageService.FALLBACK_LANG);
    } else {
      this.#lang = LanguageService.FALLBACK_LANG;
    }

    if (!this.isSupportedLang(this.#lang)) {
      this.#lang = LanguageService.FALLBACK_LANG;
    }

    this.changeLang(this.#lang);

    return this.#lang;
  }

  get currentLocale(): { name: string; value: string } {
    return this.SUPPORTED_LANGUAGES.find((item) => item.value === this.lang);
  }

  /**
   * 현재 지원하는 언어
   */
  SUPPORTED_LANGUAGES = [
    { name: '한국어', value: 'ko' },
    { name: '日本語', value: 'ja' },
    // { name: 'English', value: 'en' },
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private platformService: PlatformService
  ) {}

  /** 표시 언어 상태를 변경하고 스토리지에 저장 */
  changeLang(lang: string): void {
    if (!lang) {
      this.changeLang(LanguageService.FALLBACK_LANG);
      return;
    }

    if (!this.isSupportedLang(lang)) {
      this.changeLang(LanguageService.FALLBACK_LANG);
      console.error('target language not supported');
      return;
    }

    this.#lang = lang;
    this.translateService.use(lang);
    this.document.documentElement.lang = lang;

    if (this.platformService.isPlatformBrowser) {
      localStorage.setItem('lang', lang);
    }
  }

  private isSupportedLang(lang: string): boolean {
    return !!this.SUPPORTED_LANGUAGES.find((item) => item.value === lang);
  }
}
