import {
  Component,
  Inject,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

export interface InputDialogConfig {
  fields: FormlyFieldConfig[];
  model?: { [key: string]: any };
  header?: string;
  hasOkButton?: boolean;
}

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements AfterViewInit {
  public fields: FormlyFieldConfig[];
  public model: { [key: string]: any };
  public header: string;
  public hasOkButton: boolean;

  public form: FormGroup = new FormGroup({});
  public options: FormlyFormOptions = {};

  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>, //
    @Inject(MAT_DIALOG_DATA) public data: InputDialogConfig,
    private _change: ChangeDetectorRef
  ) {
    // this.fields = data.fields;
    this.fields = data.fields ? JSON.parse(JSON.stringify(data.fields)) : {};
    this.model = data.model ? JSON.parse(JSON.stringify(data.model)) : {};
    this.header = data.header;
    this.hasOkButton = data.hasOkButton !== undefined ? data.hasOkButton : true;
  }

  ngAfterViewInit(): void {
    if (!this.hasOkButton) {
      this.form.disable();
    }

    this._change.detectChanges();
  }

  public onClickOk(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.dialogRef.close(this.form.getRawValue());
  }

  public onClickCancle(): void {
    this.dialogRef.close();
  }
}
