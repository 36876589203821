<h2 mat-dialog-title *ngIf="header">{{ header }}</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <formly-form
      [form]="form"
      [fields]="fields"
      [model]="model"
      [options]="options"
    >
    </formly-form>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    *ngIf="hasOkButton"
    (click)="onClickOk()"
  >
    {{ 'ok' | translate }}
  </button>
  <button mat-raised-button color="warn" (click)="onClickCancle()">
    {{ 'cancel' | translate }}
  </button>
</mat-dialog-actions>
