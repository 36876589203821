<ng-container [ngSwitch]="type">
  <input
    *ngSwitchCase="'number'"
    matInput
    type="number"
    autocomplete="off"
    [id]="id"
    [readonly]="to.readonly"
    [required]="to.required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="$any(formControl)"
    [formlyAttributes]="field"
    [tabindex]="to.tabindex"
    [placeholder]="to.placeholder"
  />
  <input
    *ngSwitchCase="'password'"
    type="password"
    matInput
    autocomplete="on"
    [id]="id"
    [readonly]="to.readonly"
    [required]="to.required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="$any(formControl)"
    [formlyAttributes]="field"
    [tabindex]="to.tabindex"
    [placeholder]="to.placeholder"
  />
  <input
    *ngSwitchDefault
    matInput
    autocomplete="off"
    [type]="to.type"
    [id]="id"
    [readonly]="to.readonly"
    [required]="to.required"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="$any(formControl)"
    [formlyAttributes]="field"
    [tabindex]="to.tabindex"
    [placeholder]="to.placeholder"
  />
</ng-container>
