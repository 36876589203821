<!-- <article class="section">
  <footer id="footer2">
    <div class="inner">
      <p class="logo">
        <img src="/assets/images/logo_wt.png">
      </p>
      <div class="info">
        <div class="contact">
          <ul>
            <li><span>연락처 :  </span>070-7510-7554</li>
            <li><span>이메일 :  </span>paywith.co@gmail.com</li>
            <li><span>대표이사 : </span>채 원</li>
          </ul>
        </div>
        <div class="company">
          <ul>
            <li><span>본사 : </span><address>세종특별자치시 연동면 송암로 197, 110호 <br class="br_mobile" />(퓨처밸리 아이빌트 창업보육센터)</address></li>
            <li><span>연구소 : </span><address>서울특별시 금천구 가산동 가산디지털2로 173 <br class="br_mobile" />에이스 비즈포레 7층 716호</address></li>
            <li><span>사업자등록번호 : </span>802-88-01096</li>
          </ul>
        </div>
      </div>
      <div class="copyright">
        <span>Copyright ⓒ PayWith. All rights reserved.</span>
      </div>
    </div>
  </footer>
</article> -->
<!-- <footer id="footer" class="new-footer">
  <div class="inner instead">
    <div class="footer_logo_img">
      <img src="/assets/images/paywith_logo_white.png" alt="" class="mdn">
      <img src="/assets/images/logo_color_1.5-1.png" alt="" class="pdn">
    </div>
    <div class="footer_impormation">
      <address>
        <div class="company_value">㈜페이위드</div>
        <div class="instead">
          <div class="interval">
            <span>본사 &#58; </span>
            <span>세종특별자치시 연동면 송암로 197, 110호(퓨처밸리 아이빌트) </span>
          </div>
          <div>
            <span>연구소 &#58; </span>
            <span>서울시 금천구 가산동 가산디지털2로 173 에이스 비즈포레 716호</span>
          </div>
        </div>
      </address>
      <div class="instead">
        <div class="instead">
          <span class="instead interval mf">
            <div>대표이사 &#58; </div>
            <div>채원</div>
          </span>
          <span class="instead interval mf">
            <div>사업자등록번호 &#58; </div>
            <div>802-88-01096</div>
          </span>
          <span class="instead interval mf">
            <div>TEL &#58; </div>
            <div>070-7510-7554</div>
          </span>
        </div>
        <div>Copyright ⓒ PayWith. All rights reserved.</div>
      </div>
    </div>
  </div>
</footer> -->

<!-- footer -->
<footer class="footer">
  <div class="footer-wrap">
    <img [src]="logoPath" alt="footer-logo" />

    <div class="footer-detail-wrap">
      <span class="company-title">{{ 'companyNameSymbol' | translate }}</span>

      <div class="company-info">
        {{ 'officeAddress' | translate }}
      </div>
      <!-- <div class="company-info">
      연구소 : 서울시 금천구 가산동 가산디지털2로 173 에이스 비즈포레 716호      </div> -->

      <div class="company-info">
        <span>{{ 'officeCEO' | translate }}</span
        ><span>{{ 'officeBizNo' | translate }}</span
        ><span>{{ 'officeTelNo' | translate }}</span>
      </div>

      <div class="copyright">
        <span
          >COPYRIGHT ⓒ {{ 'companyNameEn' | translate }}. ALL RIGHTS
          RESERVED.</span
        >
      </div>
    </div>
  </div>

  <div class="mobile-wrap">
    <strong class="title">{{ 'companyNameSymbol' | translate }}</strong>
    <p class="content">
      <!-- 본사&nbsp;:&nbsp;세종특별자치시&nbsp;연동면&nbsp;송암로&nbsp;197,&nbsp;110호(퓨처밸리&nbsp;아이빌트)<br/> -->
      <span>{{ 'officeAddress' | translate }}</span
      ><br />
      <!-- 연구소&nbsp;:&nbsp;서울시&nbsp;금천구&nbsp;가산동&nbsp;가산디지털2로&nbsp;173&nbsp;에이스&nbsp;비즈포레&nbsp;716호<br /> -->
      <span>{{ 'officeCEO' | translate }}</span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ 'officeBizNo' | translate }}</span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>TEL : 070-7510-7554</span><br />
      COPYRIGHT&nbsp;ⓒ&nbsp;{{
        'companyNameEn' | translate
      }}.&nbsp;ALL&nbsp;RIGHTS&nbsp;RESERVED.
    </p>
  </div>
</footer>
