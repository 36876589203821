import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BooleanPipe } from './boolean.pipe';
import { DataPipe } from './data.pipe';
import { EnumPipe } from './enum.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import { WeekdayPipe } from './weekday.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    DataPipe,
    EnumPipe,
    BooleanPipe,
    WeekdayPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    DataPipe,
    EnumPipe,
    BooleanPipe,
    WeekdayPipe,
  ],
})
export class PipesModule {}
