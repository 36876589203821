import { formatDate, formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * 데이터 가공 Pipe
 */
@Pipe({
  name: 'data',
})
export class DataPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  // transform(value: any, ...args: string[]): string | string[] {
  /**
   * translatePrefix : 이 값이 있다면 value 값을 번역한다
   * */
  transform(
    value: any,
    type?: string,
    tsType?: string,
    translatePrefix?: string,
    ...args: string[]
  ): string {
    if (value === null || value === undefined) {
      return null;
    }

    // const [type, tsType, translatePrefix] = args;

    if (!type) {
      return value;
    }
    let result: string;
    switch (type) {
      case 'Auth':
        result = value?.userNm;
        break;
      case 'LocalDate':
        result = formatDate(value, 'MM-dd', this._translate.getBrowserLang());
        break;
      case 'LocalTime':
        if (/^\d\d:\d\d:\d\d$/.test(value)) {
          result = value;
        } else if (value) {
          result = formatDate(
            value,
            'hh:mm:ss',
            this._translate.getBrowserLang()
          );
        }
        break;
      case 'LocalDateTime':
        result = formatDate(
          value,
          'yyyy-MM-dd hh:mm',
          this._translate.getBrowserLang()
        );
        break;
      case 'Enum':
        result = this._translate.instant(`REPO.ENUM.${tsType}.${value}`);
        break;
      case 'List':
        result = Array.of(value).join(', ');
        break;
      case 'StringList':
        // 서버에는 대문자로 들어가 있을 수도 있지만, 번역키는 무조건 camelCase이므로 변경
        return value?.length > 0
          ? value.map((s) => `${translatePrefix ?? ''}${s}`)
          : '';
      case 'ListJson':
        try {
          const obj = JSON.parse(value);
          result = Array.of(obj).join(', ');
          // 타입 달라서 결과 값 없으면 파싱한 값 그대로
          if (!result) {
            result = obj;
          }
        } catch (e) {
          // 파싱 실패, 기존 데이터 그대로
          result = value;
        }
        break;
      case 'Map':
        result = Object.entries(value)
          .map(([key, val]) => `${key}: ${val}`)
          .join(', ');
        break;
      case 'MapJson':
        try {
          const obj = JSON.parse(value);
          result = Object.entries(obj)
            .map(([key, val]) => `${key}: ${val}`)
            .join(', ');
          // 타입 달라서 결과 값 없으면 파싱한 값 그대로
          if (!result) {
            result = obj;
          }
        } catch (e) {
          // 파싱 실패, 기존 데이터 그대로
          result = value;
        }
        break;
      case 'Integer':
        result = formatNumber(value, 'en');
        break;
      default:
        result = translatePrefix ? translatePrefix + value : value;
    }
    return result;
  }
}
