<!-- <div class="floating_menu" (click)="goToTop()"> -->
<!-- <img src="/assets/images/floating_ico.png" alt=""> -->
<!-- <div class="floating_arrow"></div> -->
<!-- </div> -->
<div class="main-container">
  <div
    class="floating-menu-container"
    [routerLink]="['/qna']"
    [queryParams]="{ option: 'form' }"
    routerLinkActive="router-link-active"
    (click)="passParam('form')"
  >
    <div class="icon"></div>

    <label for="qna-btn" class="text">{{
      'solutionContact' | translate
    }}</label>
  </div>
  <div class="flating-contaner" (click)="goToTop()">
    <div class="squre-top"></div>
    <div class="squre-bottom">
      <div class="bottom-squre">
        <div class="top-to-container">
          <span class="top-text">TOP</span>
          <div class="arrow-icon"></div>
        </div>
      </div>
      <div class="bottom-tri"></div>
    </div>
  </div>
</div>
